import { OutputData } from "@editorjs/editorjs";

interface LexicalNode {
  type: string;
  text: string;
  children: LexicalNode[];
  format: number;
  style: string;
  [key: string]: any;
}

interface EditorJSBlock {
  id: string;
  type: string;
  data: any;
}

interface EditorJSTableBlock {
  id: string;
  type: string;
  data: {
    withHeadings: boolean;
    content: string[][];
  };
}

const convertLexicalTableToEditorJS = (tableNode: any): EditorJSBlock => {
  const editorJSTableBlock: EditorJSTableBlock = {
    id: Math.random().toString(36).substring(2, 11),
    type: "table",
    data: {
      withHeadings: false,
      content: [],
    },
  };

  tableNode.children.forEach((node) => {
    node.children.forEach((rowNode, rowIndex) => {
      if (rowNode.type === "tablerow") {
        const row: string[] = [];
        rowNode.children.forEach((cellNode) => {
          if (cellNode.type === "tablecell") {
            const cellContent = convertTextFormat(cellNode);
            row.push(cellContent);

            // Check if the first row contains headings
            if (rowIndex === 0 && cellNode.getHeaderState() === 1) {
              editorJSTableBlock.data.withHeadings = true;
            }
          }
        });
        editorJSTableBlock.data.content.push(row);
      }
    });
  });

  return editorJSTableBlock;
};

const convertListItemToEditorJSListBlock = (listNode: LexicalNode): EditorJSBlock[] => {
  const blocks: EditorJSBlock[] = [];
  listNode.children?.forEach((childNode) => {
    if (childNode.type === "listitem") {
      blocks.push({
        id: Math.random().toString(36).substring(2, 11),
        type: childNode.listType === "bullet" ? "unorderedList" : childNode.listType === "number" ? "orderedList" : "blockChecklist",
        data: { text: convertTextFormat(childNode) },
      });
    } else if (childNode.type === "list") {
      blocks.push(...convertListItemToEditorJSListBlock(childNode));
    }
  });
  return blocks;
};

const convertTextFormat = (childNode: LexicalNode): string => {
  let formattedText = childNode.text || "";

  if (childNode.format & 1) formattedText = `<b style=${childNode.style ? `${childNode.style}` : ""}>${formattedText}</b>`; // Bold
  if (childNode.format & 2) formattedText = `<i style=${childNode.style ? `${childNode.style}` : ""}>${formattedText}</i>`; // Italic
  if (childNode.format & 8) formattedText = `<u class="cdx-underline" style=${childNode.style ? `${childNode.style}` : ""}>${formattedText}</u>`; // Underline
  if (childNode.format & 128) formattedText = `<mark class="cdx-marker" style=${childNode.style ? `${childNode.style}` : ""}>${formattedText}</mark>`; // Marker
  if (childNode.format & 16) formattedText = `<code class="inline-code" style=${childNode.style ? `${childNode.style}` : ""}>${formattedText}</code>`; // Inline Code
  if (childNode.type === "link") formattedText = `<a href="${childNode.url}" target="_blank">${formattedText}</a>`; // Link
  if (childNode.style)
    formattedText = `<font style=${childNode.style}>${formattedText}</font>`; // Color
  else formattedText = formattedText;
  return formattedText;
};

const processNode = (node: LexicalNode): EditorJSBlock[] => {
  const blocks: EditorJSBlock[] = [];

  if (node.type === "slid-paragraph") {
    const formattedText = node.children?.map((child) => convertTextFormat(child)).join("");
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "paragraph",
      data: { text: formattedText },
    });
  } else if (node.type === "slid-heading") {
    const formattedText = node.children?.map((child) => convertTextFormat(child)).join("");
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: node.tag === "h1" ? "header1" : node.tag === "h2" ? "header2" : "header3",
      data: { text: formattedText, level: node.tag === "h1" ? 2 : node.tag === "h2" ? 3 : 4 },
    });
  } else if (node.type === "list") {
    blocks.push(...convertListItemToEditorJSListBlock(node));
  } else if (node.type === "link") {
    const linkText = node.children?.[0]?.text || "";
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "paragraph",
      data: { text: `<a href="${node.url}" target="_blank">${linkText}</a>` },
    });
  } else if (node.type === "slid-link-preview") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "linkTool",
      data: {
        link: node.link,
        meta: {
          title: node.meta.title,
          description: node.meta.description,
          image: node.meta.image.url,
        },
      },
    });
  } else if (node.type === "code") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "codeMirrorTool",
      data: {
        code: node.code,
        language: node.language,
        theme: "Dark",
      },
    });
  } else if (node.type === "equation") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "Math",
      data: {
        math: node.equation,
      },
    });
  } else if (node.type === "horizontalrule") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "divider",
      data: { divider: false },
    });
  } else if (node.type === "slid-video") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "video",
      data: {
        videoUrl: node.videoUrl,
        posterUrl: node.posterUrl,
      },
    });
  } else if (node.type === "slid-image-text-extractor") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "codeTool",
      data: {
        code: node.text,
      },
    });
  } else if (node.type === "table") {
    blocks.push(convertLexicalTableToEditorJS(node));
  } else if (node.type === "image") {
    blocks.push({
      id: Math.random().toString(36).substring(2, 11),
      type: "image",
      data: {
        clipKey: node.clipKey,
        documentKey: node.documentKey,
        timestamp: node.timestamp,
        src: node.src,
        videoInfo: node.videoInfo,
        type: node.sourceType,
        originalImageSrc: node.originalImageSrc,
        isUploaded: node.isUploaded,
        caption: node.caption,
        withBorder: node.withBorder,
        withBackground: node.withBackground,
        stretched: node.stretched,
      },
    });
  } else if (node.children) {
    node.children.forEach((child) => {
      blocks.push(...processNode(child));
    });
  }
  return blocks;
};

export const convertLexicalJSToEditorJS = (lexicalData: any): OutputData => {
  const blocks = lexicalData.root.children.flatMap((node) => processNode(node));
  return {
    time: new Date().getTime(),
    blocks: blocks,
    version: "2.28.0-rc.3",
  };
};
