//auth 2.0
import EditorJS, { API } from "@editorjs/editorjs";
import BlockList from "components/editor/blocks/LEGACY_blockList";
import Checklist from "components/editor/blocks/LEGACY_checklist";
import Header from "components/editor/blocks/LEGACY_header/index";
import BlockChecklist from "components/editor/blocks/blockChecklist";
import CodeTool from "components/editor/blocks/code";
import CodeMirrorTool from "components/editor/blocks/codeMirror";
import DividerTool from "components/editor/blocks/divider/index";
import Header1 from "components/editor/blocks/header1/index";
import Header2 from "components/editor/blocks/header2/index";
import Header3 from "components/editor/blocks/header3/index";
import Image from "components/editor/blocks/image/index";
import ImageUploader from "components/editor/blocks/imageUploader/index";
import LaTeXTool from "components/editor/blocks/latex";
import List from "components/editor/blocks/list/index";
import Loader from "components/editor/blocks/loader/index";
import NestedList from "components/editor/blocks/nestedList";
import OrderedList from "components/editor/blocks/orderedList";
import Paragraph from "components/editor/blocks/paragraph/index";
import Table from "components/editor/blocks/table";
import UnorderedList from "components/editor/blocks/unorderedList";
import Video from "components/editor/blocks/video/index";
import VideoLoader from "components/editor/blocks/videoLoader/index";
import InlineCode from "components/editor/tools/inlineCode/index";
import Color from "components/editor/tools/textColor/index";
import Underline from "components/editor/tools/underline/index";
import MarkdownRules from "components/editor/utils/MarkdownRules";
import { useHistory } from "react-router-dom";
import { SlidFeatures } from "utils/privilegeManager";
import Audio from "../blocks/audio";
import AudioLoader from "../blocks/audioLoader";
import AutoNotesLoader from "../blocks/autoNotes";
import LinkTool from "../blocks/linkTool";
import MobileAutoNoteBlock from "../blocks/mobileAutoNote";
import EditorSTTBlock from "../blocks/smartLiveText";

/**
 * A complete set of all available tools in the editor
 */
export enum EditorBlockType {
  paragraph = "paragraph",
  header1 = "header1",
  header2 = "header2",
  header3 = "header3",
  unorderedList = "unorderedList",
  orderedList = "orderedList",
  checklist = "checklist",
  blockChecklist = "blockChecklist",
  loader = "loader",
  image = "image",
  video = "video",
  videoLoader = "videoLoader",
  codeTool = "codeTool",
  codeMirrorTool = "codeMirrorTool",
  Math = "Math",
  ImageUploader = "ImageUploader",
  table = "table",
  divider = "divider",
  smartLiveText = "smartLiveText",
  mobileAutoNote = "mobileAutoNote",
  autoNotesLoader = "autoNotesLoader",
  linkTool = "linkTool",

  underline = "underline", // INLINE TOOLS
  marker = "marker", // INLINE TOOLS
  inlineCode = "inlineCode", // INLINE TOOLS
  textColor = "textColor", // INLINE TOOLS

  audio = "audio", //LEGACY
  audioLoader = "audioLoader", //LEGACY
  list = "list", //LEGACY
  nestedList = "nestedList", //LEGACY
  blockList = "blockList", //LEGACY
  header = "header", //LEGACY
}

export const EditorBlockClasses = {
  paragraph: {
    class: Paragraph,
  },
  header1: {
    class: Header1,
  },
  header2: {
    class: Header2,
  },
  header3: {
    class: Header3,
  },
  unorderedList: {
    class: UnorderedList,
  },
  orderedList: {
    class: OrderedList,
  },
  blockChecklist: {
    class: BlockChecklist,
  },
  loader: {
    class: Loader,
  },
  image: {
    class: Image,
  },
  video: {
    class: Video,
  },
  videoLoader: {
    class: VideoLoader,
  },
  codeTool: {
    class: CodeTool,
  },
  codeMirrorTool: {
    class: CodeMirrorTool,
  },
  Math: {
    class: LaTeXTool,
  },
  ImageUploader: {
    class: ImageUploader,
  },
  table: {
    class: Table,
  },
  divider: {
    class: DividerTool,
  },
  smartLiveText: {
    class: EditorSTTBlock,
  },
  mobileAutoNote: {
    class: MobileAutoNoteBlock,
  },
  autoNotesLoader: {
    class: AutoNotesLoader,
  },
  linkTool: {
    class: LinkTool,
  },

  // S of inline tools
  inlineCode: {
    class: InlineCode,
  },
  underline: {
    class: Underline,
  },
  marker: {
    class: Color,
  },
  textColor: {
    class: Color,
  },
  // E of inline tools
  // S of legacy blocks
  audio: {
    class: Audio,
  },
  audioLoader: {
    class: AudioLoader,
  },
  checklist: {
    class: Checklist,
  },
  header: {
    class: Header,
  },
  list: {
    class: List,
  },
  nestedList: {
    class: NestedList,
  },
  blockList: {
    class: BlockList,
  },
  // E of legacy blocks
};

type EditorBlocksConfig = {
  [key in EditorBlockType]: any;
};

export interface EditorToolsConfig {
  editorInstance: EditorJS & API;
  isSharingMode: boolean;
  saveDocument: (fetchCurrentDocument?: boolean) => Promise<void>;
  history: ReturnType<typeof useHistory>;
  onClickMarkup: (params: any) => void;
  onClickFullScreen: (params: any) => void;
  showModal: (modalProps: any) => void;
  closeModal: () => void;
  showToast: (message: string) => void;
  confirmPrivilege: (privilege: SlidFeatures) => boolean;
  showInsufficientPrivilegeModal: () => void;
}

export const getEditorJSTools = ({
  isSharingMode,
  saveDocument,
  history,
  onClickMarkup,
  onClickFullScreen,
  showModal,
  closeModal,
  showToast,
  confirmPrivilege,
  showInsufficientPrivilegeModal,
}: EditorToolsConfig): EditorBlocksConfig => {
  return {
    // S of inline tools
    underline: {
      class: Underline,
      shortcut: "CMD+U",
    },
    inlineCode: {
      class: InlineCode,
      shortcut: "CMD+E",
    },
    marker: {
      class: Color,
      config: {
        colorCollections: [
          "rgb(255, 255, 255)",
          "rgb(255, 236, 236)",
          "rgb(255, 239, 219)",
          "rgb(255, 251, 191)",
          "rgb(231, 255, 201)",
          "rgb(217, 254, 252)",
          "rgb(229, 244, 255)",
          "rgb(243, 236, 255)",
          "rgb(255, 236, 255)",
        ],
        type: "marker",
      },
    },
    textColor: {
      class: Color,
      config: {
        type: "text",
      },
    },
    // E of inline tools
    paragraph: {
      class: Paragraph,
      config: {
        preserveBlank: true,
        MarkdownRules,
      },
      inlineToolbar: true,
    },
    header1: {
      class: Header1,
      config: {
        placeholder: "Enter a header",
        levels: [2],
        defaultLevel: 2,
      },
      inlineToolbar: true,
    },
    header2: {
      class: Header2,
      config: {
        placeholder: "Enter a header",
        levels: [3],
        defaultLevel: 3,
      },
      inlineToolbar: true,
    },
    header3: {
      class: Header3,
      config: {
        placeholder: "Enter a header",
        levels: [4],
        defaultLevel: 4,
      },
      inlineToolbar: true,
    },
    unorderedList: {
      class: UnorderedList,
      data: {
        items: [{ text: "" }],
        indentLevel: 0,
      },
      inlineToolbar: true,
    },
    orderedList: {
      class: OrderedList,
      data: {
        items: [{ text: "" }],
        indentLevel: 0,
      },
      inlineToolbar: true,
    },
    blockChecklist: {
      class: BlockChecklist,
      inlineToolbar: true,
    },
    loader: {
      class: Loader,
      inlineToolbar: false,
    },
    image: {
      class: Image,
      inlineToolbar: false,
      config: {
        onClickMarkup,
        onClickFullScreen,
        isSharingMode,
        saveDocument,
        history,
        showModal,
        closeModal,
        confirmPrivilege,
        showInsufficientPrivilegeModal,
      },
    },
    video: {
      class: Video,
      inlineToolbar: false,
    },
    videoLoader: {
      class: VideoLoader,
      inlineToolbar: false,
    },
    codeTool: {
      class: CodeTool,
      inlineToolbar: false,
    },
    codeMirrorTool: {
      class: CodeMirrorTool,
      inlineToolbar: false,
      config: {
        alwaysUseRecentLanguage: true,
        alwaysUseRecentTheme: true,
      },
    },
    Math: {
      class: LaTeXTool,
      inlineToolbar: false,
    },
    ImageUploader: {
      class: ImageUploader,
      inlineToolbar: false,
    },
    table: {
      class: Table,
      inlineToolbar: true,
    },
    divider: {
      class: DividerTool,
      inlineToolbar: false,
      config: {
        focusSelf: false,
      },
    },
    audio: {
      class: Audio,
    },
    audioLoader: {
      class: AudioLoader,
    },
    linkTool: {
      class: LinkTool,

      config: {
        //@ts-ignore
        showToast,
      },
      inlineToolbar: true,
    },
    smartLiveText: {
      class: EditorSTTBlock,
      inlineToolbar: false,
    },
    mobileAutoNote: {
      class: MobileAutoNoteBlock,
      inlineToolbar: false,
    },
    // LEGACY blocks
    header: {
      class: Header, //LEGACY
      config: {
        placeholder: "Enter a header",
        levels: [2, 3, 4],
        defaultLevel: 2,
      },
      inlineToolbar: true,
    },
    nestedList: {
      class: NestedList, //LEGACY
      inlineToolbar: true,
    },
    checklist: {
      class: Checklist, // LEGACY
      inlineToolbar: true,
    },
    blockList: {
      class: BlockList, //LEGACY
      inlineToolbar: true,
    },
    list: {
      class: List, //LEGACY
      inlineToolbar: true,
    },
    autoNotesLoader: {
      class: AutoNotesLoader,
    },
  };
};
